@font-face {
    font-family: Montserrat-ExtraBold;
    src: url("/Montserrat/static/Montserrat-ExtraBold.ttf");
}
@font-face {
    font-family: Montserrat-bold;
    src: url("/Montserrat/static/Montserrat-Bold.ttf");
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url('/Montserrat/static/Montserrat-SemiBold.ttf');
}
@font-face {
    font-family: Montserrat-Light;
    src: url('/Montserrat/static/Montserrat-Light.ttf');
}
@font-face {
    font-family: Montserrat-Medium;
    src: url('/Montserrat/static/Montserrat-Medium.ttf');
}
@font-face {
    font-family: Montserrat-Regular;
    src: url('/Montserrat/static/Montserrat-Regular.ttf');
}
$primary: #3a009c;
$secondary: #fc83da;
$section: #712abc;
$yellow: #f7f739;
$lightBg: #e9f2f7;
$breakpoint-sm: 767px;
$breakpoint-xs: 600px;

@mixin flex($direction : center) {
    display: flex;
    justify-content: $direction;
    align-items: center;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Nav {
    width: 100%;
    height: 8vh;
    min-height: 50px;
    background-color: $primary;
    color:white;
    @include flex($direction : space-between);
    padding: 0 100px;
    overflow: hidden;
    font-family: Montserrat-SemiBold;
    .head{
        img {
            width:50%;
            padding:10px 0;
        }
    }
    ul {
        list-style-type: none;
        @include flex($direction : flex-end);
        margin: 0;
        li a{
            font-size: 18px;
            padding: 0 18px;
            cursor: pointer;
            text-decoration: none;
            color:white;
        }
        .apply {
           background-color: $yellow;
           color:$primary;
           padding: 8px 15px 8px 0px;
           border-radius: 20px;
           img {
            transform: translateX(8px);
           }
        }
        .active {
            color: $yellow;
        }
    }
    .toggle{
        display: none;
        font-size: 25px;
        cursor: pointer;
    }
    @media screen and (max-width: 1080px) {
        padding: 0 30px;
        ul {
            li a{
                font-size: 15px;
                padding: 0 15px;
            }
        }
     }
    
    @media screen and (max-width: $breakpoint-sm) { 
        z-index: 2;
        ul {
            width: 100%;
            height:150px;
            position: absolute;
            left: 0;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: $primary;
            transition: all 0.5s ease;
            z-index: 1;
            li a{
                padding: 5px 0;
            }
        }
        .enableNav {
            opacity: 1;
            top: 8vh;
        }
        .disableNav {
            opacity: 0;
            top: -100%;
        }
        .toggle{
            display: block;
            z-index: 1;
        }
      }
}
.arrow-icon img{
    position: absolute;
    bottom: 10px;
    left:50%;
    transform: translate(-50%,-10px);
    width:100px;
    height: 100px;
    cursor: pointer;
}
.main {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 92vh;
    scroll-behavior: smooth;
    @media screen and (max-width: 1080px) {
        height: 100%;
    }
}
.Header {
    scroll-snap-align:start;
    .carousel{
        .carousel-inner{
            .first-banner, .second-banner{
                width: 100%;
                height: 92vh;
                min-height: 500px;
                @include flex();
                .left-section {
                   width: 50%;
                   height: 100%;
                   background-size: cover;
                   background-repeat: no-repeat;
                   @include flex();
                   img{
                    width: 80%;
                    padding: 0 20px;
                   }
                }
                .right-section {
                    width: 50%;
                    height: 100%;
                    @include flex();
                    flex-direction: column;
                    font-family: Montserrat-ExtraBold;
                    text-align: center;
                   h1{
                    line-height: 0.9;
                   }
                    p{
                        padding-top: 10px;
                        font-family: Montserrat-Regular;
                        font-size: 25px;
                    }
                }
            }
            .first-banner{
                .left-section {
                    background-image: url('../Assets/Amili\ Header/Biryani.png');
                 }
                 .right-section {
                     background-color: #eb4ecd;
                     color:$yellow;
                 }
            }
            .second-banner{
                .left-section { 
                    background-image: url('../Assets/Amili\ Header/Lemak.png');
                }
                .right-section {
                    background-color:$yellow;
                    color:#eb4ecd;
                }
            }
    }
    }
    @media screen and (max-width: $breakpoint-sm) {
        .carousel{
            .carousel-inner{
                .first-banner, .second-banner{
                    height: 100%;
                    flex-direction: column;
                    .left-section {
                       width: 100%;
                       min-height: 400px;
                       img{
                        width: 80%;
                       }
                    }
                    .right-section {
                        width: 100%;
                        padding: 100px 0;
                        p{
                            padding-top: 8px;
                            font-family: Montserrat-Regular;
                            font-size: 20px;
                        }
                    }
                }
        }
        }
     }
}
.sec-page, .seven-page, .eight-page{
    height: 92vh;
    min-height: 500px;
    overflow: hidden;
    background-color: $secondary;
    @include flex();
    border-bottom: 2px solid white;
    position: relative;
    padding:0 100px;
    scroll-snap-align:start;
    font-family: Montserrat-Regular;
    line-height: 1.3;
    p{
        color:$primary;
        font-size: 48px;
        text-align: center;
        text-align-last: center;
        span{
            color:$yellow;
        }
        .bold{
            font-family: Montserrat-Bold;
        }
    }
    .scoop-img {
        img{
            width: 350px;
            transform: translateX(50px);
        }
    }
    @media screen and (max-width: 1080px) {
        padding:30px;
        height: 100%;
        min-height: 100%;
        p{
            font-size: 25px;
            margin-bottom: 150px;
        }
    }
    @media screen and (max-width: $breakpoint-sm) {
        p{
            font-size: 20px;
        }
        .scoop-img {
            img{
                width: 200px;
                transform: translateX(0);
            }
        }
    }
}
.sec-page {
    flex-direction: column;
}
.third-page{
    height: 92vh;
    min-height: 600px;
    position: relative;
    overflow: hidden;
    scroll-snap-align:start;
    .left-image {
        h1{
            width: 33.33333333%;
            position: absolute;
            top: 50px;
            text-align: center;
            font-size: 100px;
            font-weight: 600;
            color:$yellow;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 2px;
        }
        img{
            width:100%;
            height:100%;
            background-size: cover;
            object-fit: cover;
        }
    }
    .right-content{
        background-color: $section;
        @include flex();
        flex-direction: column; 
        padding: 20px 40px;
        border-bottom: 2px solid white;
        .row{
            width: 100%;
            padding: 20px 0;
            .left-text {
                color:white;
                font-size: 25px;
                font-family: Montserrat-Medium;
                line-height: 1.2;  
            }
            .poopimg{
                img{
                    width:100px;
                }
                transform: translate(-10px,-20px);
            }
            .right-text {
                font-size: 22px;
                font-family: Montserrat-Light;
                line-height: 1.2;
                color:$yellow;
                padding: 0 60px 0 30px;
            }
        }
    }
    @media screen and (max-width: 1080px) {
        height: 100%;
        .right-content{
            padding: 30px 10px;
            font-size: 15px;
            .row{
                .left-text {
                    font-size: 20px;            
                }
                .poopimg{
                    img{
                        width:60px;
                    }
                    transform: translate(-10px,-10px);
                }
                .right-text {
                    font-size: 20px;
                    
                }
            }
        }
     }
    @media screen and (max-width: $breakpoint-sm) {
        .left-image {
            h1{
                width: 100%;
            }
        }
        .right-content{
            padding: 30px 30px;
            .row{
                text-align: center;
                .left-text {
                    padding: 0;           
                }
                .poopimg{
                    transform: translate(0px, 0px);
                }
                .right-text {
                    padding: 0;  
                }
            }
        }
     }
}

.fourth-page {
    scroll-snap-align:start;
    .carousel{
    .carousel-inner{
        .innerDiv{
            width: 100%;
            height: 100%;
            min-height: 780px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        font-size: 20px;
        font-family: Montserrat-Light;
        text-align: center;
        .header{
            width: 100%;
            padding-top: 20px;
            img{
                width:100%;
                max-width: 400px;
            }
        }
        .content{
            transform: translateY(-70px);
        }
        .dark {
            background-color: $primary;
            color:white;
            padding: 0 100px;
            width:100%;
            height: 92vh;
            min-height: 500px;
            .header {
                text-align: right;
            }
        }
        .light{
            background-color: $lightBg;
            color:$primary;
            padding: 0 100px;
            width:100%;
            height: 92vh;
            min-height: 500px;
            .header {
                text-align: left;
            }
        }
        h3{
            padding: 0 120px 30px;      
            font-size: 22px;
            span{
                font-style: italic;
            }
        }
        p{
            padding: 0 200px;
            font-size: 18px;
        }
        .left-svg{
            @include flex($direction: flex-start);
            img {
                transform: translateY(65px);
                width: 130px;
            }
        }
        .right-svg{
            @include flex($direction: flex-end);
            img {
                transform: translateY(-95px);
                width: 130px;
            }
        }
    }
    }
    .carousel-indicators{
        padding-bottom: 30px;
        button {
          background: $yellow !important;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          border: none;
          margin: 0 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        .carousel{
            transform: translateY(0px);
            .carousel-inner {
                .content{
                    transform: translateY(0) !important;
                    margin-top:50px;
                }
                .dark, .light {
                    padding: 30px;
                    height: 100%;
                    min-height: 780px;
                }
                h3{
                    padding: 0 80px 30px;             
                    font-size: 20px;
                }
                p{
                    padding: 0 150px;
                    font-size: 15px;
                }
                .left-svg{
                    img {
                        transform: translateY(40px);
                        width: 80px;
                    }
                    
                }
                .right-svg{
                    img {
                        transform: translateY(-80px);
                        width: 80px;
                    }
                }
            }
        }
        
     }
    @media screen and (max-width: $breakpoint-sm) {  
        .carousel{
            .carousel-inner{
              p, h3{
                padding: 0;
                padding-bottom: 30px;
                font-size: 15px;
              }
              p{
                padding-bottom: 0px;
                margin: 0;
              }
              .left-svg, .right-svg {
               img{
                width: 60px;
                transform: translateY(0);
               }
            }
            }
            
        }
}
}
.six-page{
    height: 92vh;
    min-height: 500px;
    overflow: hidden;
    background-color: $secondary;
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 2px solid white;
    scroll-snap-align:start;
    h1{
        font-size: 140px;
        font-family: Montserrat-bold;
        line-height: 0.9;
        color:$primary;
        span{
            color:$yellow;
        }
    }
    .step-head{
        font-family: Montserrat-Light;
        font-size: 30px;
    }
    p{
        font-family: Montserrat-Regular;
        color:$primary;
        font-size: 23px;  
    }
    .steps{
        width: 100%;
        @include flex($direction: space-around);
        flex-wrap: wrap;
        padding-bottom: 20px;
        font-family: Montserrat-bold;
        .step{
            width:200px;
            height: 200px;
            border-radius: 50%;
            background-color: $yellow;
            padding: 30px;
            text-align: center;
            color:$primary;
            font-size: 23px;
            @include flex();
        }
        span{
            font-size: 40px;
            color:$yellow;
        }
    }
    @media screen and (max-width: 1080px) {
        height: 100%;
        padding: 30px;
        h1{
            width: 100%;
            text-align: center;
            font-size: 120px;
        }
        .step-head{
            width: 100%;
            text-align: center;
            font-size: 25px;
        }
        p{
            width: 100%;
            text-align: center;
            font-size: 20px;  
        }
        .steps {
            .step{
                margin: 20px;
            }
            span {
                display: none;
            }
        }
     }
     @media screen and (max-width: $breakpoint-sm) {
        h1{
            font-size: 100px;
        }   
    }
    @media screen and (max-width: $breakpoint-xs) {
        h1{
            font-size: 60px;
        }
}
}
.seven-page{
    flex-direction: column;
    padding: 0 100px;
    p{
        font-size: 35px;
        font-family: Montserrat-Regular;
        line-height: 1.2;
        padding: 0 70px;
        margin-bottom: 0;
    }
    .head{
        padding-bottom: 20px;
    }
    .small{
        font-size: 20px;
        padding:20px 0;
    }
    a {
        .poopImg{
            width:60px;
            height: 60px;
        }
        .flagImg {
            width: 60px;
            margin-left: 20px;
        }
        background-color: $primary;
        color:$yellow;
        font-size: 30px;
        font-family: Montserrat-bold;
        padding: 15px 30px;
        border: 0;
        outline: 0;
        border-radius: 50px;
        text-decoration: none;
        &:hover {
            background-color: $yellow;
            color:$primary;
        }
    }
    @media screen and (max-width: 1080px) {
      padding: 30px;
      p{
        padding: 0;
        font-size: 20px;
      }
      a {
        .poopImg{
            width: 30px;
            height: 30px;
        }
        .flagImg {
            width: 30px;
            margin-left: 10px;
        }
        font-size: 20px;
        padding: 10px 20px;
    }
    }
}
.eight-page {
    flex-direction: column;
    padding: 0 100px;
    p{
        font-size: 30px;
        font-family: Montserrat-Medium;
        line-height: 1.2;
    }
    h1{
        font-size: 45px;
        font-family: Montserrat-Bold;
        color:$yellow;
    }
    .social-links{
        list-style: none;
        padding: 0;
        @include flex();
        li{
            padding: 0 10px;
            a{
                color: $primary;
                font-size: 35px;
            }
        }
    }
    .boxes {
        padding: 60px 0;
        img {
            width: 100%;
            max-width:200px;
        }
    }
    @media screen and (max-width: $breakpoint-sm) {
        height: 100%;
        padding: 30px;
        .boxes {
            text-align: center;
            img {
                margin: 10px 0;
            }
        }
        p{
         font-size: 20px;
         margin: 0;
        }
        h1{
            font-size: 25px;
        }  
        .social-links{
            li{
                a{
                    font-size: 25px;
                }
            }
        }   
     }
}
.footer {
    height: 92vh;
    min-height: 500px;
    overflow: hidden;
    background-color: $primary;
    color:white;
    padding: 0 30px;
    text-align: center;
    @include flex();
    flex-direction: column;
    scroll-snap-align:start;
    .row {
        width:80%;
        h3{
            font-family: Montserrat-Regular;
            font-size: 18px;
        }
        img{
            width: 100%;
            max-width:350px;
        }
    }
    .info {
        margin:30px 10px;
        p{
            font-size: 18px;
            font-family: Montserrat-Light;
            text-align: center;
            padding: 40px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            a{
                text-decoration: none;
                color:white;
            }
        }
    }
    .privacy {
        width:60%;
        div p, div a{
            text-decoration: none;
            color:white;
            font-size: 18px;
            font-family: Montserrat-Light;
            text-align: center;
            margin: 2px;
            svg{
                font-size: 20px;
                margin: 0 15px 2px 0;
            }
        }
    }
    @media screen and (max-width: 1080px) {
        height: 100%;
        padding: 30px;
        .row{
            width:100%;
        }
        .info {
            p{
                padding: 20px;
            }
        }
        .privacy {
            width:100%;
            div p, div a{
                svg{
                    margin: 0 10px 2px 0;
                }
            }
        }
     }
}
.terms {
    height: 92vh;
    background-color: $section;
    color:white;
    @include flex;
    font-size: 50px;
    @media screen and (max-width: $breakpoint-sm) {
        font-size: 30px;
    }
}
.lightbox{
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    .container{
        width: 80%;
        background-color:$secondary;
        margin: 0;
        padding: 0;
        .close{
            position: relative;
            z-index: 100;
            svg{
                position: absolute;
                top:10px;
                right: 10px;
                font-size: 20px;
                color:white;
                cursor: pointer;
            }
           
        }
        .carousel{
            margin: 10px;
            .carousel-item{
                text-align: center;
                img{
                    width: 100%;
                    max-width: 400px;
                }
            }
        }
    }
    @media screen and (max-width: $breakpoint-sm) {
        .container{
            width: 90%;
        }
    }
}